<template>
  <div style="row-gap: 1rem"
       :class="{ 'pl-1': $route.path.includes('new_contact') }"
  >
    <timeline-filters
      v-if="!hideFilters"
      :default-assigned="defaultUser"
      @reload-timeline="setFilters"
    />

    <sw-filters-modern
      v-model="filters"
      class="mb-50"
      @change="resolveFilters"
      @search="search = $event.option; loadTimeline()"
      @refresh="loadTimeline"
    />

    <!-- Timeline Components -->
    <div
      v-if="!loading"
      class="pb-25"
    >
      <app-timeline class="ml-0">
        <component
          :is="`timeline-${option.type}`"
          v-for="(option, index) in timeline"
          :key="option + '__' + index"
          :is-contact="isContact"
          v-bind="{ data: {...option.data}, timelineDate: option.timelineDate, threadId: option.thread ? option.thread.id : null, action: option.action, thread: option.thread, automation: option.automation }"
          @remove-item="removeTimelineItem(index)"
        />

        <div
          v-if="!timeline.length"
          class="p-2 text-center text-primary"
        >
          <feather-icon icon="XCircleIcon" />
          {{ $t('NoData') }}
        </div>
      </app-timeline>

      <b-pagination
        v-model="pagination.currentPage"
        :total-rows="pagination.totalItemCount"
        :per-page="pagination.limit"
        align="center"
      />
    </div>

    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-spinner
        variant="primary"
        class="m-5"
      />
    </div>
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import vSelect from 'vue-select'
// TimeLine sections
import TimelineActivities from '@/views/core/globalTimeline/TimelineActivities.vue'
import TimelineFilters from '@/views/core/globalTimeline/TimelineFilters.vue'
import TimelineStats from '@/views/core/globalTimeline/TimelineStats.vue'
import Offer from '@/views/components/timeline/Offer.vue'
import OfferMessage from '@/views/components/timeline/OfferMessage.vue'
import { mapGetters } from 'vuex'
import ContactThread from '@/views/components/timeline/ContactThread.vue'
import Order from '@/views/components/timeline/Order.vue'
import Automation from '@/views/components/timeline/Automation.vue'
import TimelineAddonFilters from '@/views/core/globalTimeline/TimelineAddonFilters.vue'
import i18n from '@/libs/i18n'
import Mail from '../views/components/timeline/Mail.vue'
import Sms from '../views/components/timeline/Sms.vue'
import Note from '../views/components/timeline/Note.vue'
import Task from '../views/components/timeline/Task.vue'
import Checklist from '../views/components/timeline/Checklist.vue'
import Complaint from '../views/components/timeline/Complaint.vue'
// eslint-disable-next-line no-unused-vars
import { GET_TIMELINE, GET_TIMELINE_STATS } from '../@constants/mutations'

export default {
  name: 'SwTimeline',
  components: {
    TimelineAddonFilters,
    AppTimeline,
    vSelect,
    BPagination,
    'timeline-mail': Mail,
    'timeline-contactThreadSms': Sms,
    'timeline-contactThreadNote': Note,
    'timeline-contactThreadTask': Task,
    'timeline-contactThreadChecklistPointDatum': Checklist,
    'timeline-contactThreadCartOffer': Offer,
    'timeline-contactThread': ContactThread,
    'timeline-contactThreadCartOfferMessage': OfferMessage,
    'timeline-contactThreadCartOrder': Order,
    'timeline-automation': Automation,
    'timeline-contactThreadComplaint': Complaint,
    TimelineFilters,
    TimelineActivities,
    TimelineStats,
  },
  props: {
    isContact: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultTabs: {
      type: Array,
      required: false,
      default: () => [],
    },
    propsUser: {
      type: Object,
      required: false,
      default: null,
    },
    hideFilters: {
      type: Boolean,
      default: false,
    },
    thread: {
      default: null,
    },
    funnel: {
      default: null,
    },
    funnelPoint: {
      default: null,
    },
    contacts: {
      default: () => [],
    },
  },
  data: vm => ({
    loading: false,
    timeline: [],
    addonFilters: {
      contactThreadTask: {
        status: '',
        type: -1,
      },
    },
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },

    activities: [],
    filtersOther: {
      timeRange: '',
      contacts: '',
      funnel: '',
      funnelPoint: '',
      thread: '',
    },
    tabs: [
      {
        key: 'contactThread',
        label: i18n.tc('Thread'),
        reqPermissions: [
          vm.$roles.CONTACT_THREAD_VIEW_ALL,
          vm.$roles.CONTACT_THREAD_VIEW_STRUCTURE,
          vm.$roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST,
          vm.$roles.CONTACT_THREAD_VIEW_ASSIGNED,
        ],
      },
      {
        key: 'contactThreadNote',
        label: i18n.tc('timeline.ContactThreadNote'),
        reqPermissions: [
          vm.$roles.CONTACT_THREAD_NOTE_VIEW_ALL,
          vm.$roles.CONTACT_THREAD_NOTE_VIEW_SELF,
          vm.$roles.CONTACT_THREAD_NOTE_VIEW_SHARED,
        ],
        reqViews: [vm.$viewRanks.SHOW_CONTACT_CARD_NOTES],
      },
      {
        key: 'contactThreadTask',
        label: i18n.tc('timeline.ContactThreadTask'),
        reqPermissions: [
          vm.$roles.CONTACT_THREAD_TASK_VIEW_ALL,
          vm.$roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED,
          vm.$roles.CONTACT_THREAD_TASK_VIEW_STRUCTURE,
        ],
        reqViews: [vm.$viewRanks.SHOW_CONTACT_CARD_TASKS],
      },
      {
        key: 'contactThreadSms',
        label: i18n.tc('timeline.ContactThreadSms'),
        reqPermissions: [
          vm.$roles.CONTACT_THREAD_SMS_VIEW_ALL,
          vm.$roles.CONTACT_THREAD_SMS_VIEW_SHARED,
          vm.$roles.CONTACT_THREAD_SMS_VIEW_SELF,
        ],
        reqViews: [vm.$viewRanks.SHOW_CONTACT_CARD_SMS],
      },
      {
        key: 'contactThreadChecklistPointDatum',
        label: i18n.tc('timeline.ContactThreadChecklistPointDatum'),
        reqPermissions: [vm.$roles.CONTACT_THREAD_CHECKLIST_VIEW_ALL],
        reqViews: [vm.$viewRanks.SHOW_CONTACT_CARD_CHECKLISTS],
      },
      {
        key: 'mail',
        label: i18n.tc('timeline.Mail'),
        reqPermissions: [vm.$roles.CONTACT_THREAD_MAIL_VIEW_ALL],
        reqViews: [vm.$viewRanks.SHOW_CONTACT_CARD_EMAILS],
      },
      {
        key: 'contactThreadCartOffer',
        label: i18n.tc('timeline.ContactThreadOffer'),
        reqModule: 'offerModule',
        reqPermissions: [vm.$roles.CONTACT_THREAD_OFFER_VIEW_ALL, vm.$roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL, vm.$roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF],
        reqViews: [vm.$viewRanks.SHOW_CONTACT_CARD_OFFERS],
      },
      {
        key: 'contactThreadCartOfferMessage',
        label: i18n.tc('timeline.OfferMessages'),
        reqModule: 'offerModule',
        reqPermissions: [vm.$roles.CONTACT_THREAD_OFFER_VIEW_ALL, vm.$roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL, vm.$roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF],
        reqViews: [vm.$viewRanks.SHOW_CONTACT_CARD_OFFERS],
      },
      {
        key: 'contactThreadCartOrder',
        label: i18n.tc('timeline.ContactThreadOrder'),
        reqModule: 'orderModule',
        reqPermissions: [vm.$roles.CONTACT_THREAD_ORDER_VIEW_ALL, vm.$roles.CONTACT_THREAD_ASSIGNED_ORDER_VIEW_ALL, vm.$roles.CONTACT_THREAD_ASSIGNED_ORDER_VIEW_SELF],
        reqViews: [vm.$viewRanks.SHOW_CONTACT_CARD_ORDERS],
      },
      {
        key: 'automation',
        label: i18n.tc('Automation'),
        reqViews: [vm.$viewRanks.SHOW_CONTACT_CARD_AUTOMATIONS],
        reqPermissions: [vm.$roles.AUTOMATION_VIEW_ALL],
        reqModule: 'automationModule',
      },
      {
        key: 'contactThreadComplaint',
        label: i18n.tc('Complaints'),
        reqModule: 'complaintModule',
        reqPermissions: [
          vm.$roles.CONTACT_THREAD_COMPLAINT_VIEW_ALL,
          vm.$roles.CONTACT_THREAD_ASSIGNED_COMPLAINT_VIEW_ALL,
          vm.$roles.CONTACT_THREAD_ASSIGNED_COMPLAINT_VIEW_SELF,
        ],
      },
    ],
    sections: [],
    search: '',
    pagination: {
      limit: 25,
      currentPage: 1,
      totalItemCount: 0,
    },
    filters: [
      {
        type: 'refresh',
      },
      {
        name: i18n.t('Pagination'),
        type: 'radio',
        key: 'pagination',
        active: {
          label: 25,
          value: 25,
        },
        options: [
          {
            label: 10,
            value: 10,
          },
          {
            label: 25,
            value: 25,
          },
          {
            label: 60,
            value: 60,
          },
          {
            label: 100,
            value: 100,
          },
        ],
      },
      {
        name: i18n.tc('Sections'),
        key: 'sections',
        type: 'checkbox',
        pending: true,
        active: [],
        options: [],
      },
      {
        name: `${i18n.t('Search')}…`,
        type: 'search',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      system: 'system/getSettings',
      reloadContent: 'modal/getReloadContent',
    }),
    defaultUser() {
      return this.propsUser ? [this.propsUser] : null
    },
    visibleTabs() {
      return this.tabs
        .filter(tab => !tab?.requiredViews || this.checkRequiredViewPermissions(tab.requiredViews))
        .filter(tab => !tab?.requiredModule || this.checkRequiredModule(tab.requiredModule))
        .filter(tab => !tab?.requiredPermissions || this.checkRequiredPermissions(tab.requiredPermissions))
    },
  },
  watch: {
    '$route.params.threadId': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.loadTimeline()
      },
    },
    funnel(n, o) { if (n !== o) this.init() },
    funnelPoint(n, o) { if (n !== o) this.init() },
    thread(n, o) { if (n !== o) this.init() },
    contacts(n, o) { if (n !== o) this.init() },
    reloadContent(newValue) {
      if (newValue) this.loadTimeline()
    },
    sections: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.loadTimeline()
        })
      },
    },
    pagination: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.loadTimeline()
        })
      },
    },
    system: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.init()
        })
      },
    },
  },
  mounted() {
    const fSections = this.filters.find(f => f.key === 'sections')

    if (fSections) {
      const options = this.visibleTabs

      fSections.pending = true
      fSections.options = options
      fSections.active = options
      fSections.pending = false

      this.resolveFilters({
        filter: fSections,
        option: options,
      })
    }

    this.init()
  },
  methods: {
    init() {
      if (this.funnel) {
        this.filtersOther.funnel = this.getObjectId(this.funnel)
      }
      if (this.funnelPoint) {
        this.filtersOther.funnelPoint = this.getObjectId(this.funnelPoint)
      }
      if (this.thread) {
        this.filtersOther.thread = this.getObjectId(this.thread)
      }
      if (this.contacts) {
        this.filtersOther.contacts = (this.contacts || []).mapKeys('id')
      }

      this.loadTimeline()
    },
    removeTimelineItem(index) {
      if (index < 0) return
      this.$delete(this.timeline, index)
    },
    loadTimeline() {
      this.loading = true

      this.$nextTick(() => {
        const payload = {
          filters: this.filtersOther,
          addonFilters: this.addonFilters,
          search: this.search,
          pagination: this.pagination,
          types: this.sections.map(({ key }) => key),
        }

        this.$store.dispatch(`timeline/${GET_TIMELINE}`, payload)
          .then(res => {
            const timeline = []
            res.data.items.forEach(timelineItem => {
              let { type } = timelineItem
              // eslint-disable-next-line no-self-assign
              if (['contactThreadTask', 'contactThreadChecklistPointDatum'].includes(type)) {
                type += 'Log'
              }

              if (['automation'].includes(type)) {
                type = 'automationProcedureLog'
              }

              if (['contactThread'].includes(type)) {
                type = 'contactThreadFunnelProcessLog'
              }

              const item = {
                type: timelineItem.type,
                data: timelineItem[type],
                timelineDate: timelineItem.createdAt.date.split('.')[0],
                action: timelineItem.action,
                thread: timelineItem?.contactThread,
                automation: timelineItem?.fromAutomationProcedureLog || null,
              }

              timeline.push(item)
            })
            // eslint-disable-next-line no-nested-ternary
            this.timeline = timeline
            this.pagination.totalItemCount = res.data.totalItemCount
            this.loading = false
          })
          .catch(err => {
            this.loading = false

            // eslint-disable-next-line no-prototype-builtins
            if (err.hasOwnProperty('message')) {
              return
            }

            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      })
    },
    resolveFilters({ filter, option }) {
      if (filter.key === 'sections') this.sections = option
      if (filter.key === 'pagination') this.pagination.limit = option.value
    },
    setFilters(filters) {
      this.$set(this, 'filtersOther', filters)

      this.$nextTick(() => this.loadTimeline())
    },
  },
}
</script>
