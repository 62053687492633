<template>
  <component
    :is="isMobile ? 'div' : 'app-timeline-item'"
    variant="success"
    icon="ListIcon"
    class="pb-2 d-flex flex-column justify-content-start align-items-start position-relative"
    style="row-gap: .34rem"
  >
    <slot />

    <component
      :is="isContact ? 'div' : 'b-card'"
      class="w-100 rounded-lg mb-0 position-relative"
      no-body
    >
      <b-card-body :class="`p-${ isContact ? '0' : '1' }`">
        <div
          class="d-flex mb-50"
          style="column-gap: .5rem"
        >
          <b-avatar
            v-if="isMobile"
            rounded="sm"
            size="sm"
            variant="light-success"
          >
            <span class="d-flex align-items-center">
              <feather-icon
                icon="ListIcon"
                size="14"
              />
            </span>
          </b-avatar>

          <router-link
            v-if="thread && !isContact"
            class="font-small-3"
            :class="{ 'deleted-text': thread.contact.deletedAt }"
            :to="`/contact/${thread.contact.id}/thread/${thread.id}/timeline`"
          >
            {{ thread.contact.firstName }} {{ thread.contact.lastName }} – {{ thread.name }}
          </router-link>

          <p
            v-else-if="isContact"
            class="mb-0"
          >
            {{ $t('CurrentContact') }}
          </p>
        </div>

        <div
          class="w-100 font-weight-bold d-flex flex-row flex-wrap justify-content-between"
          style="column-gap: .64rem; row-gap: .34rem;"
        >
          <div
            class="d-flex flex-wrap flex-row align-items-center"
            style="column-gap: .5rem; row-gap: .34rem;"
          >
            <b-badge
              v-if="automation && automation.automationProcedure"
              class="mr-25"
              variant="primary"
            >
              <feather-icon icon="CpuIcon" />
              {{ $t('Automation') }}: {{ automation.automationProcedure.name }}
            </b-badge>

            <b-badge
              variant="light-primary"
              class="d-flex flex-wrap flex-row align-items-center"
              style="row-gap: .34rem;"
            >
              <div
                class="d-flex flex-wrap flex-row align-items-center"
                style="row-gap: .34rem; column-gap: .34rem;"
              >
                <feather-icon icon="ListIcon" />
                {{ $t('timeline.Task') }}
              </div>

              <template v-if="action === 'changeStatus'">
                <feather-icon
                  icon="ArrowRightIcon"
                  class="ml-25 mr-50"
                />

                <div
                  class="d-flex flex-wrap flex-row align-items-center"
                  style="row-gap: .34rem; column-gap: .34rem;"
                >
                  <feather-icon
                    icon="StarIcon"
                  />
                  <span>
                    {{ $t(`task.status.${data.contactThreadTask.status}`) }}
                  </span>
                </div>
              </template>
            </b-badge>

            <div
              class="d-flex flex-row align-items-center"
              style="column-gap: .5rem; row-gap: .34rem;"
            >
              <small class="text-lowercase">{{ $t('FromTheDay') }}</small>

              <span class="font-italic">{{ formatDate(timelineDate, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}</span>
            </div>

            <div
              v-if="data.contactThreadTask.assignedUser"
              class="d-flex flex-row align-items-center"
              style="column-gap: .34rem"
            >
              <avatar
                :user="data.contactThreadTask.createdBy"
                size="sm"
                show-tooltip
                :show-name="false"
                class="mr-n25"
              />

              <span class="font-small-2 text-lowercase">{{ $t('CreatedFor') }}</span>

              <avatar
                :user="data.contactThreadTask.assignedUser"
                size="sm"
                show-tooltip
                :show-name="false"
                class="mr-n25"
              />
            </div>
          </div>

          <div
            class="d-flex flex-row flex-wrap align-items-center"
            style="column-gap: .64rem; row-gap: .34rem;"
          >
            <b-button-group
              v-if="checkRequiredPermissions([
                $roles.CONTACT_THREAD_TASK_CHANGE_STATUS_ALL,
                $roles.CONTACT_THREAD_TASK_CHANGE_STATUS_ASSIGNED,
              ])"
            >
              <b-button
                v-b-tooltip.hover.v-success
                :variant="data.contactThreadTask.status !== 'D' ? 'outline-success' : 'success'"
                size="sm"
                :title="$t('MarkAsDone')"
                :class="{ 'btn-icon': data.contactThreadTask.status !== 'D' }"
                :disabled="data.contactThreadTask.status !== 'O'"
                @click="changeTaskStatus('D')"
              >
                <feather-icon icon="CheckSquareIcon" />

                <template v-if="data.contactThreadTask.status === 'D'">
                  {{ $t('task.status.D') }}
                </template>
              </b-button>

              <b-button
                v-b-tooltip.hover.v-danger
                :variant="data.contactThreadTask.status !== 'ND' ? 'outline-danger' : 'danger'"
                size="sm"
                :title="$t('MarkAsNotDone')"
                :disabled="data.contactThreadTask.status !== 'O'"
                :class="{ 'btn-icon': data.contactThreadTask.status !== 'ND' }"
                @click="changeTaskStatus('ND')"
              >
                <feather-icon icon="XSquareIcon" />

                <template v-if="data.contactThreadTask.status === 'ND'">
                  {{ $t('task.status.ND') }}
                </template>
              </b-button>
            </b-button-group>

            <b-button-group v-if="data.contactThreadTask.status === 'O'">
              <b-button
                v-if="checkRequiredPermissions([
                  $roles.CONTACT_THREAD_TASK_EDIT,
                  { role: $roles.CONTACT_THREAD_TASK_EDIT_SELF, data: data.contactThreadTask, field: 'createdBy', userId: currentUser.id },
                ]) && isTimeLimitReached(data.contactThreadTask.createdAt.date, null, 'contactThreadTaskDeleteTime')"
                v-b-tooltip.hover.v-warning
                variant="outline-warning"
                size="sm"
                :title="$t('Edit')"
                class="btn-icon"
                @click="editTask(data.contactThreadTask)"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>

              <b-button
                v-if="checkRequiredPermissions([
                  $roles.CONTACT_THREAD_TASK_DELETE,
                ])"
                v-b-tooltip.hover.v-danger
                variant="outline-danger"
                size="sm"
                :title="$t('Delete')"
                class="btn-icon"
                @click="deleteTask(data.contactThreadTask.id, data.contactThreadTask)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </b-button-group>

            <b-button-group v-if="data.contactThreadTask.status !== 'O'">
              <b-button
                v-b-tooltip.hover.v-warning
                variant="outline-warning"
                size="sm"
                :title="$t('RepeatTask')"
                class="btn-icon"
                @click="editTask(data.contactThreadTask, true)"
              >
                <feather-icon icon="RefreshCwIcon" />
              </b-button>

            <!--            <b-button-->
            <!--              v-if="data.contactThreadTask.contactThreadTaskType.isReportRequired"-->
            <!--              v-b-tooltip.hover.v-primary-->
            <!--              variant="outline-primary"-->
            <!--              size="sm"-->
            <!--              :title="$t('ShowReport')"-->
            <!--              class="btn-icon"-->
            <!--              @click="openModal('viewModal', $t('PreviewReport'), '', data.contactThreadTask.contactThreadTaskReport)"-->
            <!--            >-->
            <!--              <feather-icon icon="FileIcon" />-->
            <!--            </b-button>-->
            </b-button-group>
          </div>
        </div>

        <hr class="border-light-primary">

        <div
          class="d-flex flex-column px-md-50"
          style="row-gap: .5rem"
        >
          <div
            class="w-100 d-flex flex-column justify-content-start align-items-start"
            style="row-gap: .34rem"
          >
            <div
              class="w-100 d-flex flex-row flex-wrap align-items-center justify-content-between"
              style="column-gap: .5rem; row-gap: .34rem;"
            >
              <div
                class="d-flex flex-row flex-wrap align-items-center"
                style="column-gap: .5rem; row-gap: .34rem;"
              >
                <b-badge variant="light-primary">
                  {{ $t('task.Type') }}

                  -

                  {{ data.contactThreadTask.contactThreadTaskType.name }}
                </b-badge>

                <div
                  class="d-flex flex-wrap flex-row"
                  style="column-gap: .34rem"
                >
                  {{ $t('Reminder') }}:

                  <b-badge
                    :variant="data.contactThreadTask.sendReminderEmail ? 'light-success' : 'light-danger'"
                  >
                    <feather-icon :icon="data.contactThreadTask.sendReminderEmail ? 'CheckSquareIcon' : 'XSquareIcon'" />

                    {{ $t('Email') }}
                  </b-badge>

                  <b-badge
                    :variant="data.contactThreadTask.sendReminderSms ? 'light-success' : 'light-danger'"
                  >
                    <feather-icon :icon="data.contactThreadTask.sendReminderSms ? 'CheckSquareIcon' : 'XSquareIcon'" />

                    {{ $t('Sms') }}
                  </b-badge>
                </div>
              </div>

              <div
                class="d-flex flex-wrap flex-row align-items-center"
                style="column-gap: .5rem; row-gap: .34rem;"
              >
                <small>{{ $t('TaskTerm') }}</small>

                <span class="font-italic">
                  {{ formatDate(data.contactThreadTask.startAt.date.split('.')[0], { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}

                  {{ $t('To').toLowerCase() }}

                  {{ formatDate(data.contactThreadTask.endAt.date.split('.')[0], { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
                </span>
              </div>
            </div>

            <div
              v-if="data.contactThreadTask.content"
              v-html="data.contactThreadTask.content"
            />

            <documents-list
              v-if="data.contactThreadTask.files.length"
              :documents="data.contactThreadTask.files"
            />
          </div>

          <b-alert
            v-if="data.contactThreadTask.contactThreadTaskReport && data.contactThreadTask.contactThreadTaskReport.content"
            variant="primary"
            show
            class="mb-0"
          >
            <div class="alert-body">
              <strong>{{ $t('timeline.Task_report') }}:</strong>

              <div v-html="(data.contactThreadTask.contactThreadTaskReport.content || '').replaceAll('\n', '<br>')"></div>
            </div>
          </b-alert>
        </div>
      </b-card-body>
    </component>

    <hr
      v-if="isContact"
      class="w-100 mb-0"
    >
  </component>
</template>

<script>
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { BButtonGroup, VBTooltip } from 'bootstrap-vue'
import { DELETE_CONTACT_TASK, OPEN_MODAL, UPDATE_CONTACT_TASK } from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import * as roles from '@/helpers/permissions'
import timeLimiter from '@/helpers/timeLimiter'
import { formatDate } from '@core/utils/filter'
import useSwUtils from '@/composable/useSwUtils'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'
import DocumentsList from '../DocumentsList.vue'

export default {
  name: 'Task',
  components: {
    DocumentsList,
    AppTimelineItem,
    BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['data', 'timelineDate', 'threadId', 'action', 'thread', 'isContact', 'automation'],
  data: () => ({
    roles,
  }),
  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
    }),
  },
  methods: {
    formatDate,
    timeLimiter,
    openModal(modalType = 'note', modalTitle = 'Nowa Notatka', okButtonTitle = 'Dodaj notatkę', data) {
      this.$root.$emit('bv::hide::popover')
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType,
          modalTitle,
          okButtonTitle,
          threadFor: this.threadId,
          data,
        })
    },
    editTask(task, repeat = false) {
      const taskData = task
      if (repeat) delete taskData.id

      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'task',
          size: 'lg',
          modalTitle: this.$i18n.t('EditTask'),
          okButtonTitle: this.$i18n.t('Save'),
          threadFor: this.threadId,
          editedData: taskData,
        })
    },
    deleteTask(taskId) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          this.$store.dispatch(`singleContact/${DELETE_CONTACT_TASK}`, { taskId, threadId: this.threadId })
            .then(() => {
              this.$el.parentNode.removeChild(this.$el)
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
        })
    },
    taskTypeVariant(value) {
      let variant = 'light-primary'
      if (value === 'D') variant = 'light-success'
      else if (value === 'O') variant = 'light-info'
      else if (value === 'ND') variant = 'light-danger'
      return variant
    },
    changeTaskStatus(statusValue) {
      const data = {
        id: this.data.contactThreadTask.id.toString(),
        status: statusValue,
      }
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          if (this.data.contactThreadTask.contactThreadTaskType.isReportRequired) {
            this.$store.commit(`modal/${OPEN_MODAL}`,
              {
                modalType: 'report',
                modalTitle: this.$i18n.t('NewReport'),
                okButtonTitle: this.$i18n.t('AddReport'),
                threadFor: this.threadId,
                data: { ...this.data.contactThreadTask, statusValue },
              })
          } else {
            this.$store.dispatch(`singleContact/${UPDATE_CONTACT_TASK}`, { updateTaskData: data, threadId: this.threadId })
              .then(() => {
                this.showToast('success', this.$i18n.t('StatusUpdated'))
              })
              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              })
          }
        })
    },
  },
  setup() {
    const { isTimeLimitReached } = useSwUtils()
    const { isMobile, resizeHandler } = useVerticalLayout()

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isTimeLimitReached,
      isMobile,
    }
  },
}
</script>

<style scoped>

</style>
